import arbitrum_logo from './assets/svg/arbitrum_plain.svg';
import polygon_matic from './assets/svg/polygon_matic.svg';

import {
  StaticJsonRpcProvider,
  EtherscanProvider,
} from '@ethersproject/providers';
import Constants from 'expo-constants';
import { LogToLoot8Console } from './helpers/Loot8ConsoleLogger';
import * as FileSystem from 'expo-file-system';
import { Category } from './enums/category.enum';

let _ipfs_url = Constants.expoConfig.extra.IPFS_URL;
let _ipns_url = Constants.expoConfig.extra.IPNS_URL;
let _pubsub_url = Constants.expoConfig.extra.PUBSUB_URL;
let _api_request_timeout = Constants.expoConfig.extra.API_REQUEST_TIMEOUT;
let _app_configuration = null;
let _relayerInfo = null;
let _maintenanceInfo = {
  inMaintenance: false,
  maintenanceInfo: '',
};
let _serverTimeOffset = null;

export const refreshURLs = async () => {
  try {
    const ReqSentTimeStamp = Date.now();
    const response = await fetch(
      Constants.expoConfig.extra.IPNS_UPLOAD_LAMBDA_URL + 'status',
      { method: 'POST' },
    );
    const ResReceivedTimestamp = Date.now();
    if (response.status === 200) {
      const res = await response.json();
      _ipfs_url = res.ipfsGateway + '/';
      _ipns_url = res.ipfsGateway + '/ipns/';
      _pubsub_url = res.ipfsAPI.replace('/api/v0', '');
      if (res.timestamp) {
        //in case client clock is not in sync with server clock, store the clock skew (offset/diff) value
        const offset = Math.round(
          res.timestamp -
            ReqSentTimeStamp -
            (ResReceivedTimestamp - ReqSentTimeStamp) / 2,
        );
        //If client clock is not in sync with server clock for more then 15 seconds then need to consider clock skew for API requests
        if (
          offset &&
          offset !== 0 &&
          Math.round(Math.abs(offset / 1000)) > 15
        ) {
          _serverTimeOffset = offset;
        }
      }
      _maintenanceInfo = {
        inMaintenance: res.inMaintenance,
        maintenanceInfo: res.maintenanceInfo,
      };
    }
  } catch (error) {
    console.error('RefreshURL Error: ', error);
  }
};

export const GetDefaultLocation: any = {
  Lat: 40.718848,
  Long: -74.012298,
};

export enum NetworkId {
  ARBITRUMTEST = 421613,
  ARBITRUM = 42161,
  POLYGON_MUMBAI_TESTNET = 80001,
  POLYGON_MAINNET = 137,
  CRONOS_TESTNET = 338,
  CRONOS_MAINNET = 25,
  GOERLI = 5,
  MAINNET = 1,
  SHIB_TESTNET = 719,
  SHIB_MAINNET = 109, //TODO: Need to update chain at all places in file,
  ARBITRUM_SEPOLIA = 421614,
  ARBITRUM_NOVA = 42170,
  STAGING_ARBITRUM_NOVA = 42170999,
}
export const getUTCTime = function () {
  return new Date().getTime();
};

export const timeout = function (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getMaintenanceInfo = () => _maintenanceInfo;

export const getClockSkew = () => _serverTimeOffset;

export const IPFS_URL = () => {
  return _ipfs_url;
};

export const EST_PORTAL_API_REQUEST_TIMEOUT = () => {
  return _api_request_timeout;
};

export const EST_PORTAL_WEBHOOK_URL = (networkId: number | string) => {
  if (networkId && _relayerInfo) {
    const relayer = _relayerInfo?.find(x => x.networkId === networkId);
    if (relayer && relayer.url) {
      return relayer.url;
    }
  }
  return NETWORKS[networkId].WEBHOOK_URL;
};
export const OFFRAMP_LAMBDA_URL_PROD = () => {
  return Constants.expoConfig.extra.OFFRAMP_LAMBDA_URL_PROD;
};

export const OFFRAMP_LAMBDA_URL_DEV = () => {
  return Constants.expoConfig.extra.OFFRAMP_LAMBDA_URL_DEV;
};

export const OFFRAMP_LAMBDA_URL_STAGING = () => {
  return Constants.expoConfig.extra.OFFRAMP_LAMBDA_URL_STAGING;
};

export const TXHISTORY_LAMBDA_URL_DEV = () => {
  return Constants.expoConfig.extra.TRANSACTION_HISTORY_URL_DEV;
};

export const IPNS_UPLOAD_LAMBDA_URL =
  Constants.expoConfig.extra.IPNS_UPLOAD_LAMBDA_URL;
export const MARKET_PLACE_LAMBDA_URL =
  Constants.expoConfig.extra.MARKET_PLACE_LAMBDA_URL;
export const RESPONSIVE = Constants.expoConfig.extra.RESPONSIVE;
export const RESPONSIVE_WIDTH = '1024';

export const SOCIAL_MEDIA_API =
  Constants.expoConfig.extra.SOCIAL_MEDIA_API || IPNS_UPLOAD_LAMBDA_URL;
export const BATCHIFY_LAMBDA_URL =
  Constants.expoConfig.extra.BATCHIFY_LAMBDA_URL;
export const SMALL_IMAGE_SIZE_LIMIT = 4 * 1024 * 1024; // 4 MB

export const IPNS_URL = () => {
  return _ipns_url;
};
export const FRIENDS_IPNS_FILE_NAME = 'friends.json';
export const USERDETAIL_IPNS_FILE_NAME = 'userdetail.json';
export const PUBSUB_URL = () => {
  return _pubsub_url;
};
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export enum ToastCustomMessageType {
  INFO,
  ERROR,
}
interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  // TODO: add contract address in mainnet
  // [NetworkId.ARBITRUM]: {
  // },
  [NetworkId.ARBITRUMTEST]: {
    CollectionFactory: '0xE819FaC026621fDff989A507fE38fd4426C9F3E8',
    DAOAuthority: '0xB7433289940A0D942B37FC060fd1815C29A79d17',
    EntityRegistry: '0xc91B0d95079C931B6174a5ff394179BDD9325586',
    CollectibleFactory: '0x7Fb4CD96E74F842d18bE2b48af9a7682fef61367',
    PrivateForwarder: '0xcBE440f712b63975a54006F0C4d588a034fa2519',
    User: '0xe4DD7a2ba119c5D6078a8Fcf496043A74F5f1406',
    OrderDispatcher: '0x7cCb86A354A1f553F27B34c4b6c6E34f99259808',
    DAOERC20: '0x7729e37190a30eC529bbebD8c181Ea2797ea76c3',
    CollectionManager: '0x2D3a80C3A24cf83eAc5646917AB5F129796f7aC5',
    ExternalCollectionManager: '0xCB21Eb65723050841B8FFa849c098687de175bFA',
    CollectionHelper: '0x5D6bA8aC620C15929d095Fe9387aeaf11ADC7138',
    TokenPriceCalculator: '0x4534c0c01Ecb75c409f492965406D2D87249D28C',
    RelayerAddress: '0x80cC5Cc40aF77054D132869C1655661D7bE06ab1',
    DispatcherHelper: '0x3077e62083882427476B06c91b276f1814Bf431c',
    Loot8SignatureVerification: '0x332c7293f5e7AEee11bBAA380FA1cb68c4268AdF',
    SubscriptionManager: '0xc6Bd70136f89EEedDa7EB150838419e60632FBf3',
    Loot8Token: '0xd28343A8d0Aacac560D89A809687ea5e4154DBfF',
    MarketPlace: '0x13aA811655C9e3E3192fe63B84Ed1d2A0D56C6C3',
    USDC: '0xb0659B98Dde28Bde082D6705444273a4Fcd03dC3',
    Onboarder: '0xa41B2a85DDEfFbC538651108916623647E1dD0c2',
    LayerZeroEndPoint: '0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab',
  },
  // * TESTNET
  [NetworkId.ARBITRUM_SEPOLIA]: {
    CollectionFactory: '0x8Bf51c5EfFAAcD0474a6c8489E225f609416C2e3',
    DAOAuthority: '0x00eAceC41ca2D5d86352A90eAF66DDdE6B38b840',
    EntityRegistry: '0x228FAfC0B241d1522c9bfD2a2318A4f945B2b42e',
    CollectibleFactory: '0x8Bf51c5EfFAAcD0474a6c8489E225f609416C2e3',
    PrivateForwarder: '0x0fC89252F0994870eD41af3dE2EEaFBAc69D7063',
    User: '0x10e584566CAc365dDD35CEE6Bf2d35b58970D0B7',
    OrderDispatcher: '0x46aa48f156a3bC30B0D5F5C42cdAac23F848AE3e',
    DAOERC20: '0xA4FA1C803E9FeF752641C4C0aD7808a9681CEb13',
    CollectionManager: '0x04c21f1Aa3b9180cAed2D61caA217a1f13e6A81E',
    ExternalCollectionManager: '0x5E63812BeFFee6664eb7320128F3a955Bf1f6181',
    CollectionHelper: '0x986A164c4FB936228e4A95cfAb4641c4E2FCaE91',
    TokenPriceCalculator: '0x5F5b6e7bDE7f79EeE550f9a7a4A72b1F1e32B69B',
    RelayerAddress: '0x80cC5Cc40aF77054D132869C1655661D7bE06ab1',
    DispatcherHelper: '0x37ab7586f634aEc0B47bDd0182d43B364EA53Fb5',
    Loot8SignatureVerification: '0xF54a54512B1Ba8Ff64d97bF4d9825755f9faA4FA',
    SubscriptionManager: '0x0C5df2c901974bc020ae4C9F8b20814297e47dE4',
    Loot8Token: '0xA4FA1C803E9FeF752641C4C0aD7808a9681CEb13',
    MarketPlace: '0xfBaD71e75F1153a55158BFB45EDafacAbC7B2A85',
    OffRamp: '0x8385E0895F7E27ccEeEBdd78A74BaF599d4F7598',
    Onboarder: '0x4df6273B87c3D9f26B3b998b49cF90fB5530e2C4',
    LayerZeroEndPoint: '0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1',
    EventRegistry: '0xd1d2fe84Cf34829B7f0CEC979d96d71C34bE697a',
    Loot8TicketVerification: '0xf317c81b8B799b1E268b49d7115Bc5d37D243387',
  },
  [NetworkId.ARBITRUM]: {
    CollectionFactory: '0xb6cbCD311019aE2f2A9D7Fba7bf80dB3Cb84B866',
    DAOAuthority: '0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07',
    EntityRegistry: '0xB52de321B7cc50a679bfA972318660C9B5DC1732',
    CollectibleFactory: '0xb6cbCD311019aE2f2A9D7Fba7bf80dB3Cb84B866',
    PrivateForwarder: '0xA0C19aaa2ac7a6e5bF2012F0D66Ea9a266A0788F',
    User: '0x8f543c4077437513eac2Ffe97cC88BE2ef25550d',
    OrderDispatcher: '0xCC9601EE5dF9D612fE7E508f222c2b345b1a304d',
    DAOERC20: '0x0c810C82e8c9c31B620f13224a2866e54d9629d2',
    CollectionManager: '0x1F75698fA087F77Bd02D2636317EA12F4A74a081',
    ExternalCollectionManager: '0x9839E731e7606eC68F97b8AA4d61a3013812AfdB',
    CollectionHelper: '0x805FFB30e9e726413048973f03B3d967D7E71C54',
    TokenPriceCalculator: '0x76195282b767286B8929140a6E272C72332FDcCa',
    RelayerAddress: '0x2b7388cD2B8Cc25C91368a56F59c07c3FE46DfcA',
    DispatcherHelper: '0xC73e6Ac8612B7e44a3fC8C4b8455F9Ed44955A9c',
    Loot8SignatureVerification: '0x922b77749E4F326dFF048B1e543DB58851524F1e',
    SubscriptionManager: '0x059201b65ffa7Df5521b6E5f79dF00F0061b40E6',
    Loot8Token: '0xea0EFd0D026E6eddD61c5270bf1E22dFaC66c846',
    MarketPlace: '0xdd3D815A010Ea7005e0481B07a8b5d6024AD9F85',
    USDC: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
    Onboarder: '0xb84708dd6a8a1193Fc113299343E90C0bB569675',
    LayerZeroEndPoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
  },
  [NetworkId.POLYGON_MUMBAI_TESTNET]: {
    CollectionFactory: '0x2E17c7b53Ab12184bCa8d6A3BbF28D99AB87B7F7',
    PrivateForwarder: '0x063d056a01d5949EE5288bcB0ac7705beF059DD8',
    RelayerAddress: '0xaFdC3e383B89DEcF9c4B928183D9d8865A844412',
    USDC: '0xd61d88F6596D50Eb74C2c8dB0d0fBd49995F37Df',
    MarketPlace: '0xa7A21861F933b78F2dbadE33740001b60038e09e',
    LayerZeroEndPoint: '0xf69186dfBa60DdB133E91E9A4B5673624293d8F8',
  },
  [NetworkId.POLYGON_MAINNET]: {
    CollectionFactory: '0xCC9601EE5dF9D612fE7E508f222c2b345b1a304d',
    PrivateForwarder: '0xe0542a0ab39EB36E45a001735f952881AD44511F',
    RelayerAddress: '0x2b7388cD2B8Cc25C91368a56F59c07c3FE46DfcA',
    USDC: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    MarketPlace: '0xC1E58dc55DacADc7eCE25172C5A631eA94a0cD2A',
    LayerZeroEndPoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
  },
  // * MAINNET
  [NetworkId.ARBITRUM_NOVA]: {
    CollectionFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
    DAOAuthority: '0xEff619A93B05FBD746777C86C74f08FC0FA97D36',
    EntityRegistry: '0xcEFF1F19F6F3B9dA3bce9363D1908dD892dAa9B8',
    CollectibleFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
    PrivateForwarder: '0x086AB3dD6F57018df7A84818e676c121b3a3aBDd',
    User: '0xF4C57821b217C7891e820D98688002c6E5983a02',
    OrderDispatcher: '0x8F39506f9466019F2121fc8b6624AD00153D7F64',
    DAOERC20: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
    CollectionManager: '0x7D405530f6de57ae99C0Aef6d7251c404589e25C',
    ExternalCollectionManager: '0xBFceF9ed665b97723a4a102FDCfF85214611e396',
    CollectionHelper: '0x1F75698fA087F77Bd02D2636317EA12F4A74a081',
    TokenPriceCalculator: '0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07',
    RelayerAddress: '0x2b7388cD2B8Cc25C91368a56F59c07c3FE46DfcA',
    DispatcherHelper: '0xD3810ebeEfa414a7f14992001623F9B8d47bB1b0',
    Loot8SignatureVerification: '0xEBbD640C8d4E7aD239F65bD59B901F408DA0eD5a',
    SubscriptionManager: '0x5a782aD9aC18A8d3Db6C98c30ee8f20Ab8Bb00a6',
    Loot8Token: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
    MarketPlace: '0x7634F7315b055A6a39e7f0D55f54BE255f913783',
    OffRamp: '0x053eFFD341e69592d9e65431aF829779f70E0A89',
    Onboarder: '0xe4cb440c4502e17FF4247977956564d8D3D5c6b6',
    LayerZeroEndPoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
    Loot8TicketVerification: '0xDb2B506BB32eE6c9cA415630f3bA87767f39f4a8',
    EventRegistry: '0x29a2e3b9e4e01B411C1c5E35F67bb8C1E45589Ee',
  },
  [NetworkId.STAGING_ARBITRUM_NOVA]: {
    CollectionFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
    DAOAuthority: '0xEff619A93B05FBD746777C86C74f08FC0FA97D36',
    EntityRegistry: '0xcEFF1F19F6F3B9dA3bce9363D1908dD892dAa9B8',
    CollectibleFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
    PrivateForwarder: '0x086AB3dD6F57018df7A84818e676c121b3a3aBDd',
    User: '0xF4C57821b217C7891e820D98688002c6E5983a02',
    OrderDispatcher: '0x8F39506f9466019F2121fc8b6624AD00153D7F64',
    DAOERC20: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
    CollectionManager: '0x7D405530f6de57ae99C0Aef6d7251c404589e25C',
    ExternalCollectionManager: '0xBFceF9ed665b97723a4a102FDCfF85214611e396',
    CollectionHelper: '0x1F75698fA087F77Bd02D2636317EA12F4A74a081',
    TokenPriceCalculator: '0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07',
    RelayerAddress: '0x80cC5Cc40aF77054D132869C1655661D7bE06ab1',
    DispatcherHelper: '0xD3810ebeEfa414a7f14992001623F9B8d47bB1b0',
    Loot8SignatureVerification: '0xEBbD640C8d4E7aD239F65bD59B901F408DA0eD5a',
    SubscriptionManager: '0x5a782aD9aC18A8d3Db6C98c30ee8f20Ab8Bb00a6',
    Loot8Token: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
    MarketPlace: '0x7634F7315b055A6a39e7f0D55f54BE255f913783',
    OffRamp: '0x053eFFD341e69592d9e65431aF829779f70E0A89',
    LayerZeroEndPoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
    Onboarder: '0xe4cb440c4502e17FF4247977956564d8D3D5c6b6',
    EventRegistry: '0x20b8704Bd1678b3250e42616D6F05791030b1201',
    Loot8TicketVerification: '0x5804504Dd781937103a9fC76fb00724A28308B8d',
  },
};

/**
 * Network details required to add a network to a user's wallet, as defined in EIP-3085 (https://eips.ethereum.org/EIPS/eip-3085)
 */

interface INativeCurrency {
  name: string;
  symbol: string;
  decimals?: number;
}

interface INetwork {
  chainName: string;
  chainId: number;
  nativeCurrency: INativeCurrency;
  rpcUrls: string[];
  blockExplorerUrl: string;
  image: any; //SVGImageElement;
  imageAltText: string;
  uri: () => string;
  WEBHOOK_URL: string;
  STAGING_WEBHOOK_URL?: string;
  genesisBlockNumber: number;
}

const getURI = (networkid: number) => {
  if (
    _app_configuration?.nodeProviders &&
    _app_configuration?.nodeProviders[networkid]
  ) {
    return _app_configuration?.nodeProviders[networkid];
  } else {
    return nodeProviders[networkid];
  }
};

const getLogsProviderURI = (networkid: number) => {
  if (
    _app_configuration?.logsNodeProviders &&
    _app_configuration?.logsNodeProviders[networkid]
  ) {
    return _app_configuration?.logsNodeProviders[networkid];
  } else {
    return logsNodeProviders[networkid];
  }
};

export const getNetwork = () => {
  return Number(Constants.expoConfig.extra.NETWORK_ID);
};

export const getOldNetwork = () => {
  return Number(Constants.expoConfig.extra.OLD_NETWORK_ID);
};

export const IsLogEnable = () => {
  return Constants.expoConfig.extra.SENTRY_LOG == 'true';
};

export const slowProcessCollectionOnWeb = () => {
  return Constants.expoConfig.extra.SLOW_PROCESS_COLLECTION_ON_WEB == 'true';
};

export const gasEstimationEnabled = () => {
  return Constants.expoConfig.extra.NEED_GAS_ESTIMATION == 'true';
};
export const getNetworkName = () => {
  return NETWORKS[getNetwork()].chainName;
};
export const getNetworkRPC = () => {
  return NETWORKS[getNetwork()].rpcUrls[0];
};
export const getNetworkURI = () => {
  return NETWORKS[getNetwork()].uri();
};

export const getEtherScanProvider = () => {
  return new EtherscanProvider(getNetwork());
};

export const getAnynetEtherScanProvider = (chainId: NetworkId) => {
  return new EtherscanProvider(chainId);
};

let _staticProvider = {};
export const getStaticProvider = () => {
  const uri = NETWORKS[getNetwork()].uri();
  if (!_staticProvider[uri]) {
    _staticProvider[uri] = new StaticJsonRpcProvider(uri);
  }
  return _staticProvider[uri];
};

let _anynetStaticProvider = {};
export const getAnynetStaticProvider = (chainId: NetworkId) => {
  if (!_anynetStaticProvider[chainId]) {
    _anynetStaticProvider[chainId] = new StaticJsonRpcProvider(
      NETWORKS[chainId]?.uri(),
    );
  }
  return _anynetStaticProvider[chainId];
};

let _staticLogsProvider = {};
export const getStaticLogsProvider = (chainId: NetworkId) => {
  const uri = getLogsProviderURI(chainId);
  if (!_staticLogsProvider[chainId]) {
    _staticLogsProvider[chainId] = new StaticJsonRpcProvider(uri);
  }
  return _staticLogsProvider[chainId];
};

export const getNetworkID = (chain: number) => {
  let selectedNetworkID: NetworkId;
  switch (chain) {
    case 80001:
      selectedNetworkID = NetworkId.POLYGON_MUMBAI_TESTNET;
      break;
    case 137:
      selectedNetworkID = NetworkId.POLYGON_MAINNET;
      break;
    case 42161:
      selectedNetworkID = NetworkId.ARBITRUM;
      break;
    case 338:
      selectedNetworkID = NetworkId.CRONOS_TESTNET;
      break;
    case 25:
      selectedNetworkID = NetworkId.CRONOS_MAINNET;
      break;
    case 5:
      selectedNetworkID = NetworkId.GOERLI;
      break;
    case 1:
      selectedNetworkID = NetworkId.MAINNET;
      break;
    case 719:
      selectedNetworkID = NetworkId.SHIB_TESTNET;
      break;
    case 109:
      selectedNetworkID = NetworkId.SHIB_MAINNET;
      break;
    case 421614:
      selectedNetworkID = NetworkId.ARBITRUM_SEPOLIA;
      break;
    case 421613:
      selectedNetworkID = NetworkId.ARBITRUMTEST;
      break;
    case 42170:
      selectedNetworkID = NetworkId.ARBITRUM_NOVA;
      break;
    case 42170999:
      selectedNetworkID = NetworkId.STAGING_ARBITRUM_NOVA;
      break;
    default:
      selectedNetworkID = NetworkId.ARBITRUM_SEPOLIA;
      break;
  }
  return selectedNetworkID;
};

export const WEBHOOK_URL = (networkId: number) => {
  if (networkId && _relayerInfo) {
    const relayer = _relayerInfo?.find(x => x.networkId === networkId);
    if (relayer && relayer.url) {
      return relayer.url;
    }
  }
  return NETWORKS[networkId].WEBHOOK_URL;
};

export const getGenesisBlockNumber = (networkId: number): number => {
  if (_app_configuration?.genesisBlockNumber) {
    return _app_configuration?.genesisBlockNumber[networkId];
  } else {
    return NETWORKS[networkId].genesisBlockNumber;
  }
};

export const NETWORKS: { [key: number]: INetwork } = {
  [NetworkId.ARBITRUM]: {
    chainName: 'Arbitrum',
    chainId: NetworkId.ARBITRUM,
    nativeCurrency: {
      name: 'Arbitrum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      'https://app.loot8.io/rpc/42161/fmyholol0tax4pugrprbk3dacb2nmdav',
    ],
    blockExplorerUrl: 'https://arbiscan.io',
    image: arbitrum_logo,
    imageAltText: 'Arbitrum Mainnet',
    uri: () => getURI(NetworkId.ARBITRUM),
    WEBHOOK_URL:
      'https://loot8-relayer.loot8-services.dev/webhook/1211cbcc-aec4-4787-979b-048a49339f40',
    genesisBlockNumber: 70095541,
  },
  [NetworkId.ARBITRUMTEST]: {
    chainName: 'Arbitrum Testnet',
    chainId: NetworkId.ARBITRUMTEST,
    nativeCurrency: {
      name: 'Arbitrum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      'https://goerli-rollup.arbitrum.io/rpc',
      'https://arbitrum-goerli.infura.io/v3',
    ],
    blockExplorerUrl: 'https://goerli.arbiscan.io/',
    image: arbitrum_logo,
    imageAltText: 'Arbitrum Testnet',
    uri: () => getURI(NetworkId.ARBITRUMTEST),
    WEBHOOK_URL:
      'https://loot8-relayer-dev.neuralmetrics.ai/webhook/84779292-73b7-4d1b-a561-97546d7a4798',
    genesisBlockNumber: 12160594,
  },
  [NetworkId.POLYGON_MUMBAI_TESTNET]: {
    chainName: 'Polygon Testnet',
    chainId: NetworkId.POLYGON_MUMBAI_TESTNET,
    nativeCurrency: {
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://app.loot8.io/rpc/80001/f3w21nwivc6b2b388lcaatyzhkk9sd61',
    ],
    blockExplorerUrl: 'https://polygonscan.com/',
    image: polygon_matic,
    imageAltText: 'Polygon Testnet',
    uri: () => getURI(NetworkId.POLYGON_MUMBAI_TESTNET),
    WEBHOOK_URL:
      'https://loot8-relayer-dev.neuralmetrics.ai/webhook/eeb6dd2a-3211-446a-96a8-a37873be2c7e',
    genesisBlockNumber: 30000000,
  },
  [NetworkId.POLYGON_MAINNET]: {
    chainName: 'Polygon Mainnet',
    chainId: NetworkId.POLYGON_MAINNET,
    nativeCurrency: {
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://app.loot8.io/rpc/137/fmyholol0tax4pugrprbk3dacb2nmdav'],
    blockExplorerUrl: 'https://polygonscan.com/',
    image: polygon_matic,
    imageAltText: 'Polygon Mainnet',
    uri: () => getURI(NetworkId.POLYGON_MAINNET),
    WEBHOOK_URL:
      'https://loot8-relayer.loot8-services.dev/webhook/b7871c16-35e7-4171-b538-63c2cb66f025',
    genesisBlockNumber: 38000000,
  },
  [NetworkId.CRONOS_TESTNET]: {
    chainName: 'Cronos Testnet',
    chainId: NetworkId.CRONOS_TESTNET,
    nativeCurrency: {
      name: 'Cronos',
      symbol: 'TCRO',
      decimals: 18,
    },
    rpcUrls: [''],
    blockExplorerUrl: '',
    image: null,
    imageAltText: 'Cronos Testnet',
    uri: () => getURI(NetworkId.CRONOS_TESTNET),
    WEBHOOK_URL: '',
    genesisBlockNumber: -1500,
  },
  [NetworkId.CRONOS_MAINNET]: {
    chainName: 'Cronos Mainnet',
    chainId: NetworkId.CRONOS_MAINNET,
    nativeCurrency: {
      name: 'Cronos',
      symbol: 'CRO',
      decimals: 18,
    },
    rpcUrls: [''],
    blockExplorerUrl: '',
    image: null,
    imageAltText: 'Cronos Mainnet',
    uri: () => getURI(NetworkId.CRONOS_MAINNET),
    WEBHOOK_URL: '',
    genesisBlockNumber: -1500,
  },
  [NetworkId.GOERLI]: {
    chainName: 'Goerli Testnet',
    chainId: NetworkId.GOERLI,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [''],
    blockExplorerUrl: '',
    image: null,
    imageAltText: 'Goerli Testnet',
    uri: () => getURI(NetworkId.GOERLI),
    WEBHOOK_URL: '',
    genesisBlockNumber: 9349341,
  },
  [NetworkId.MAINNET]: {
    chainName: 'ETH Mainnet',
    chainId: NetworkId.MAINNET,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://app.loot8.io/rpc/1/fmyholol0tax4pugrprbk3dacb2nmdav'],
    blockExplorerUrl: 'https://etherscan.io/',
    image: null,
    imageAltText: 'Eth Mainnet',
    uri: () => getURI(NetworkId.MAINNET),
    WEBHOOK_URL: '',
    genesisBlockNumber: 17666174,
  },
  [NetworkId.SHIB_TESTNET]: {
    chainName: 'Shibarium Testnet',
    chainId: NetworkId.SHIB_TESTNET,
    nativeCurrency: {
      name: 'BONE',
      symbol: 'BONE',
      decimals: 18,
    },
    rpcUrls: [''],
    blockExplorerUrl: '',
    image: null,
    imageAltText: 'Shibarium Testnet',
    uri: () => getURI(NetworkId.SHIB_TESTNET),
    WEBHOOK_URL: '',
    genesisBlockNumber: 0,
  },
  [NetworkId.SHIB_MAINNET]: {
    chainName: 'Shibarium Mainnet',
    chainId: NetworkId.SHIB_MAINNET,
    nativeCurrency: {
      name: 'BONE',
      symbol: 'BONE',
      decimals: 18,
    },
    rpcUrls: [''],
    blockExplorerUrl: '',
    image: null,
    imageAltText: 'Shibarium Mainnet',
    uri: () => getURI(NetworkId.SHIB_MAINNET),
    WEBHOOK_URL: '',
    genesisBlockNumber: 0,
  },
  [NetworkId.ARBITRUM_SEPOLIA]: {
    chainName: 'Arbitrum Sepolia',
    chainId: NetworkId.ARBITRUM_SEPOLIA,
    nativeCurrency: {
      name: 'Sepolia ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      'https://app.loot8.io/rpc/421614/f3w21nwivc6b2b388lcaatyzhkk9sd61',
    ],
    blockExplorerUrl: 'https://sepolia-explorer.arbitrum.io/',
    image: arbitrum_logo,
    imageAltText: 'Arbitrum Sepolia',
    uri: () => getURI(NetworkId.ARBITRUM_SEPOLIA),
    WEBHOOK_URL:
      'https://loot8-relayer-dev.loot8-services.dev/webhook/aaae77ec-0fee-4075-9335-bc1010129632',
    genesisBlockNumber: 600000,
  },
  [NetworkId.ARBITRUM_NOVA]: {
    chainName: 'Arbitrum Nova',
    chainId: NetworkId.ARBITRUM_NOVA,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      'https://app.loot8.io/rpc/42170/fmyholol0tax4pugrprbk3dacb2nmdav',
    ],
    blockExplorerUrl: 'https://nova.arbiscan.io/',
    image: arbitrum_logo,
    imageAltText: 'Arbitrum Nova',
    uri: () => getURI(NetworkId.ARBITRUM_NOVA),
    WEBHOOK_URL:
      'https://loot8-relayer.loot8-services.dev/webhook/0e7a2121-ab76-4a32-b01d-ed98788be7f3',
    genesisBlockNumber: 27000000,
  },
  [NetworkId.STAGING_ARBITRUM_NOVA]: {
    chainName: 'Arbitrum Nova',
    chainId: NetworkId.STAGING_ARBITRUM_NOVA,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      'https://app.loot8.io/rpc/staging/42170999/femvikz02ltxdhbw78oclxt2rbgvx5by',
    ],
    blockExplorerUrl: 'https://nova.arbiscan.io/',
    image: arbitrum_logo,
    imageAltText: 'Staging Arbitrum Nova',
    uri: () => getURI(NetworkId.STAGING_ARBITRUM_NOVA),
    WEBHOOK_URL:
      'https://loot8-relayer-qa.loot8-services.dev/webhook/257933aa-84ee-4de4-8007-231e4d38f8eb',
    genesisBlockNumber: 27000000,
  },
};

export const CategoriesList = [
  {
    id: Category.OTHER,
    name: 'Other',
  },
  {
    id: Category.SPORTS,
    name: 'Sports',
  },
  {
    id: Category.MUSIC,
    name: 'Music',
  },
  {
    id: Category.CELEBRITIES,
    name: 'Celebrities',
  },
  {
    id: Category.EDUCATORS,
    name: 'Educators',
  },
  {
    id: Category.BUSINESS,
    name: 'Business',
  },
  {
    id: Category.GAMING,
    name: 'Gaming',
  },
  {
    id: Category.ARTIST,
    name: 'Artist',
  },
  {
    id: Category.FOUNDATIONS,
    name: 'Foundations',
  },
];

// app storage key
export const APP_VERSION = '@loot8_version';
export const APP_STORAGE_USER_KEY = '@user_key';
export const APP_STORAGE_USER_ADDRESS = '@user_address';
export const APP_STORAGE_NETWORKID = '@networkid';
export const APP_STORAGE_USER_NONCE = '@user_nonce';
export const NOTIFICATIONS = '@notificationsV1_';
export const APP_STORAGE_ALL_PASSPORTDETAILS = '@allpassportdetails';

export const APP_STORAGE_ENTITY_LIST = '@allentitylist';
export const APP_STORAGE_PASSPORT_LIST = '@allpassportlist';
export const APP_STORAGE_OFFER_LIST = '@allofferlist';
export const APP_STORAGE_EVENT_LIST = '@alleventlist';
export const APP_STORAGE_THIRDPARTY_LIST = '@allThirdPartylist';
export const APP_STORAGE_DIGITALCOLLECTIBLE_LIST =
  '@alldigitalcollectiablelist';
export const APP_STORAGE_USER_THIRDPARTY_LIST =
  '@alluserdigitalcollectiablelist';
export const APP_STORAGE_COLLECTIBLE_LIST = '@allcollectiblelist';
export const APP_STORAGE_SORTED_COLLECTION_LIST = '@loot8SortedCollectionList';
export const USER_WALKTHROUGH_STATUS = '@userWalkthroughStatus';
export const APP_STORAGE_TX_TIMESTAMPS = '@allTxTimestamps';
export const SHOW_APP_SELECTION = '@showAppSelection';
export const SELECTED_APP = '@selectedApp';

export const APP_STORAGE_GET_ENTITY_OFFER_LIST = entityaddress => {
  return '@entityoffer_' + entityaddress.toLowerCase();
};
export const APP_STORAGE_GET_PASSPORT_THIRDPARTY_LIST = passportAddress => {
  return '@passportthirdparty_' + passportAddress.toLowerCase();
};
export const APP_STORAGE_GET_COLLECTIBLEDETAILS = collectibleAddress => {
  return '@collectible_' + collectibleAddress.toLowerCase();
};
export const APP_STORAGE_GET_PASSPORTBALANCE = passportaddress => {
  return '@passportbalance_' + passportaddress.toLowerCase();
};
export const APP_STORAGE_GET_FRIENDS = useraddress => {
  return '@friends_' + useraddress.toLowerCase();
};
export const APP_STORAGE_GET_THIRDPARTYCOLLECTDETAILS = (
  collectiableAddress,
  chainID,
) => {
  return '@thirdparty_' + chainID + '_' + collectiableAddress.toLowerCase();
};
export const APP_STORAGE_GET_PATRONCOLLECTDETAILS = (
  collectiableType,
  address,
) => {
  return (
    '@patroncollectiblelist_' + collectiableType + '_' + address.toLowerCase()
  );
};
export const APP_STORAGE_GET_ALL_MINTED_PATRONCOLLECTDETAILS =
  collectiableType => {
    return '@patroncollectiblelist_' + collectiableType;
  };

export const APP_ALL_TRANSACTION = '@alltransaction';

export const initialBlock = 3607057;
export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';
export const EXPO_LOOT8_PROJECT_ID = 'b5788999-ddd8-43a7-8c65-102469f18ee8';

export const LAST_BLOCK_NUMBER = '@lastBlockNumber';
export const LAST_TIMESTAMP = '@lastTimeStamp';
export const PRIVATE_MESSAGE_FRIEND_REQUESTS = '@loot8-v1-pm-friend-requests';

export const LOCATION_API_TASK = 'location-api-task';
export const PASSPORT_LIFE_SPAN_KEY = '@passport_life_span_key';
export const PASSPORT_STORAGE_AGE = 5; //minutes

export const EST_PORTAL_MASTER_KEY =
  Constants.expoConfig.extra.EST_PORTAL_MASTER_KEY;
export const MASTER_KEY = Constants.expoConfig.extra.MASTER_KEY;
export const WEB_REDIRECT_URL = Constants.expoConfig.extra.WEB_REDIRECT_URL;

export const APP_CONFIGURATION_FILE = 'appconfig.json';

export const PASSPORT_MESSAGES = passportAddress =>
  '@loot8-v1-passport-messages_' + passportAddress;
export const PASSPORT_MESSAGES_LAST_READ = (
  passportAddress: string,
  userAddress: string,
) => {
  return (
    '@loot8-v1-pm-lastReadTimestamp_' +
    passportAddress.substring(0, 7) +
    (userAddress ? '_' + userAddress.substring(0, 7) : '')
  );
};
export const PASSPORT_MESSAGES_LAST_FETCH = passportAddress =>
  '@loot8-v1-pm-lastFetchTimestamp_' + passportAddress;
export const PASSPORT_MESSAGES_LAST_EXECUTION_TIME = passportAddress =>
  '@loot8-v1-pm-lastExecutionTime_' + passportAddress;

export const PASSPORT_CACHE_LAST_FETCH = passportAddress =>
  '@loot8-v1-passportRefresh_' + passportAddress;

export const TABLET_DIMENTION = { width: 768, height: 1024 };

export const LOOT8_FEED = 'loot8-feed-';
export const SOCIAL_MEDIA_SIGNER =
  Constants.expoConfig.extra.SOCIAL_MEDIA_SIGNER.toLowerCase();

export const MUTUALFRIENDS_MESSAGES_LAST_READ = (
  userAddress: string,
  friendAddress: string,
) => {
  return (
    '@loot8-v1-mfm-lastReadTimestamp_' +
    userAddress.substring(0, 7) +
    (friendAddress ? '_' + friendAddress.substring(0, 7) : '')
  );
};

export const FRIEND_PRIVATE_MESSAGES_LAST_READ = (
  userAddress: string,
  friendAddress: string,
) => {
  return (
    '@loot8-v1-friend-pm-lastReadTimestamp_' +
    userAddress.substring(0, 7) +
    (friendAddress ? '_' + friendAddress.substring(0, 7) : '')
  );
};

export const FRIEND_PRIVATE_MESSAGES_LAST_REQSENT = (
  userAddress: string,
  friendAddress: string,
) => {
  return (
    '@loot8-v1-friend-pm-lastReqSentTimestamp_' +
    userAddress.substring(0, 7) +
    (friendAddress ? '_' + friendAddress.substring(0, 7) : '')
  );
};

export const MIGRATION_STATUS = '@loot8-migration-status';
// load app configuration
const loadAppConfiguration = async () => {
  try {
    const response = await fetch(
      IPNS_URL() +
        Constants.expoConfig.extra.APP_CONFIGURATION_IPNS_KEY.replace(
          'ipns://',
          '',
        ) +
        '/' +
        APP_CONFIGURATION_FILE,
      { method: 'GET', headers: { 'Content-Type': 'application/text' } },
    );

    if (response.status === 200) {
      const configData = await response.text();
      if (configData) {
        _app_configuration = overrideAppConfig(
          JSON.parse(configData),
          Constants.expoConfig.extra,
        );
        return _app_configuration;
      }
    }
  } catch (err) {
    LogToLoot8Console('load configuration failed', err);
  }
};

// get api request timeout
export const API_REQUEST_TIMEOUT = () => {
  if (_app_configuration && _app_configuration.apiRequestTimeout) {
    _api_request_timeout = _app_configuration.apiRequestTimeout;
  }
  return _api_request_timeout;
};

export const MAX_DATE_TIME = 8640000000000000; // Sat Sep 13 275760 05:30:00 GMT+0530
export const MIN_DATE_TIME = -2209008070000; // Mon Jan 01 1900 00:00:00 GMT+0521
export const PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=com.loot8.loot8_app';
export const APPSTORE_URL = 'https://apps.apple.com/app/loot8/id1666456551';
export const PLAYSTORE_APP_URL = 'market://details?id=com.loot8.loot8_app';
export const APPSTORE_APP_URL =
  'itms-apps://apps.apple.com/id/app/loot8/id1666456551';
export const DISCORD_APPSTORE_URL =
  'https://apps.apple.com/app/discord-chat-talk-hangout/id985746746';
export const TELEGRAM_APPSTORE_URL =
  'https://apps.apple.com/app/telegram-messenger/id686449807';
export const TIKTOK_APPSTORE_URL =
  'https://apps.apple.com/us/app/tiktok/id835599320';
export const INSTAGRAM_APPSTORE_URL =
  'https://apps.apple.com/us/app/instagram/id389801252';
export const FACEBOOK_APPSTORE_URL =
  'https://apps.apple.com/us/app/facebook/id284882215';
export const TWITTER_APPSTORE_URL =
  'https://apps.apple.com/us/app/twitter/id1482454543';
export const TWITCH_LOGIN_VERIFIER_NAME = 'loot8-custom-auth-twitch';
export const TWITCH_CLIENT_ID = 'ujxg80skt89ijb4r699kju59eb21f5';

// Batchsize of requests/network calls
export const NETWORK_CALL_BATCHSIZE = 10;

// Expiry Time for stored timestamps - Cashout Loot8 Tokens - 7 Days
export const CASHOUT_TX_TIMESTAMP_EXPIRY_IN_MS = 7 * 24 * 60 * 60 * 1000;

// Estimated completion time of Tx - Cashout Loot8 Tokens - 1 hour
export const CASHOUT_EST_COMPLETION_TIME_IN_SECS = 60 * 60;

export const getAppConfiguration = async () => {
  try {
    if (_app_configuration) {
      return _app_configuration;
    } else {
      initAppConfig();
      await loadAppConfiguration();
      return _app_configuration;
    }
  } catch (e) {
    LogToLoot8Console('getAppConfiguration - app configuration failed', e);
  } finally {
    return _app_configuration;
  }
};

// initialize app configuration.
const overrideAppConfig = (config, extra): any => {
  try {
    // get relayer detail.
    if (config && config?.relayerInfo && config?.relayerInfo.length > 0) {
      _relayerInfo = config?.relayerInfo;
    } else {
      _relayerInfo = null;
    }

    LogToLoot8Console('CONFIG_ENVIRONMENT', extra.CONFIG_ENVIRONMENT);
    //setting node providers
    if (
      extra.CONFIG_ENVIRONMENT === 'local' &&
      Constants.expoConfig.extra.LOCAL_APP_CONFIG
    ) {
      const localConfig = JSON.parse(
        Constants.expoConfig.extra.LOCAL_APP_CONFIG,
      );
      try {
        config = { ...config, ...localConfig };
      } catch (error) {
        console.error('Error while parsing local config');
      }
    }
  } catch (error) {
    LogToLoot8Console('initializeAppConfiguration:', error);
  }

  return config;
};

export const IsLoot8ConsoleLogEnable = () => {
  return Constants.expoConfig.extra.LOOT8_CONSOLE_LOG == 'true';
};

// map data cache key
export const APP_MAP_COLLECTIBLE_CONSTRAINT = collectibleAddress => {
  return '@map_constraint_' + collectibleAddress.toLowerCase();
};

const initAppConfig = () => {
  _app_configuration = {
    nodeProviders,
    logsNodeProviders,
  };
};

let nodeProviders = {
  '421613':
    'https://arbitrum-goerli.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '42161': 'https://app.loot8.io/rpc/42161/fmyholol0tax4pugrprbk3dacb2nmdav',
  '80001': 'https://app.loot8.io/rpc/80001/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '137': 'https://app.loot8.io/rpc/137/fmyholol0tax4pugrprbk3dacb2nmdav',
  '338': 'https://evm-t3.cronos.org/',
  '25': 'https://evm.cronos.org/',
  '5': 'https://goerli.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '1': 'https://mainnet.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '719': 'https://puppynet.shibrpc.com',
  '421614': 'https://app.loot8.io/rpc/421614/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '42170': 'https://app.loot8.io/rpc/42170/fmyholol0tax4pugrprbk3dacb2nmdav',
  '42170999':
    'https://app.loot8.io/rpc/staging/42170999/femvikz02ltxdhbw78oclxt2rbgvx5by',
};

let logsNodeProviders = {
  '421613':
    'https://arbitrum-goerli.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '42161': 'https://app.loot8.io/rpc/42161/fmyholol0tax4pugrprbk3dacb2nmdav',
  '80001': 'https://app.loot8.io/rpc/80001/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '137': 'https://app.loot8.io/rpc/137/fmyholol0tax4pugrprbk3dacb2nmdav',
  '338': 'https://evm-t3.cronos.org/',
  '25': 'https://evm.cronos.org/',
  '5': 'https://goerli.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '1': 'https://mainnet.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '719': 'https://puppynet.shibrpc.com',
  '421614': 'https://app.loot8.io/rpc/421614/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '42170': 'https://app.loot8.io/rpc/42170/fmyholol0tax4pugrprbk3dacb2nmdav',
  '42170999':
    'https://app.loot8.io/rpc/staging/42170999/femvikz02ltxdhbw78oclxt2rbgvx5by',
};

export const enum SortingOptions {
  NONE = 0,
  ALPHABETICAL_ASC = 1,
  ALPHABETICAL_DESC = 2,
  COST_ASC = 3,
  COST_DESC = 4,
}

export const isNativeChain = (chainId: number) => {
  return (
    chainId == NetworkId.ARBITRUM_SEPOLIA ||
    chainId == NetworkId.ARBITRUM_NOVA ||
    chainId == NetworkId.ARBITRUM ||
    chainId == NetworkId.STAGING_ARBITRUM_NOVA
  );
};

let gifFileList: string[] = [];
export const pushToGIFFileList = (item: string | Array<any>) => {
  if (Array.isArray(item)) {
    gifFileList = item;
  } else {
    gifFileList.push(item);
  }
};
export const removeFromGIFFileList = (item: string) => {
  gifFileList = gifFileList.filter(
    p => p?.toLowerCase() !== item?.toLowerCase(),
  );
};
export const getGIFFileList = () => gifFileList;

export const imagesDir = `${FileSystem.documentDirectory}loot8-images/`;
export const thumbnailImagesDir = `${imagesDir}thumbnails/`;
export const optimizedImagesDir = `${imagesDir}optimized/`;
export const gifImageListFile = `${imagesDir}gifs.txt`;

// Checks if gif directory exists. If not, creates it
export const ensureDirExists = async () => {
  let config = await getAppConfiguration();
  if (config && config?.cacheImageEnabled) {
    const dirInfo = await FileSystem.getInfoAsync(imagesDir);
    if (!dirInfo.exists) {
      LogToLoot8Console("Cache Images directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(imagesDir, { intermediates: true });
    }
    const thumbnailDirInfo = await FileSystem.getInfoAsync(thumbnailImagesDir);
    if (!thumbnailDirInfo.exists) {
      LogToLoot8Console("Cache Images directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(thumbnailImagesDir, {
        intermediates: true,
      });
    }
    const optimizedDirInfo = await FileSystem.getInfoAsync(optimizedImagesDir);
    if (!optimizedDirInfo.exists) {
      LogToLoot8Console("Cache Images directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(optimizedImagesDir, {
        intermediates: true,
      });
    }
  }
};

export const MINTED_COLLECTION = collectionAddress =>
  '@minted_collection_' + collectionAddress;
